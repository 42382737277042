<template>
  Social networks
</template>

<script>
export default {
  name: "AppSocialNetworks",
  components: {
  }
}
</script>

<style scoped>

</style>