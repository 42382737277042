<!--suppress HtmlUnknownTarget -->
<template>
  <div class="row bg-light">
    <iframe class="col-md-9 mx-auto" src="JorgeTorrezCV-en.pdf#toolbar=0" style="height: 75vh;"/>
  </div>
  <div class="row bg-light pt-3">
    <a class="justify-content-center d-flex" href="JorgeTorrezCV-en.pdf" download>
      <span class="badge text-bg-dark">
        Download
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "AppCv"
}
</script>

<style scoped>

</style>