<template>
  <div class="container bg-white border border-dark rounded-3">
    <div class="row">
      <samp>
        <h3>
          <strong>
            Hello there!
          </strong>
        </h3>
        <p>
          Hello There, I'm Jorge - Bolivian - 25 years old
        </p>
        <p>
          Aura, Tyson, Flor, Tita and Sasha are my cats, they are so cute, I love them.
        </p>
        <h3>
          <strong>
            Web Development
          </strong>
        </h3>
        <p>
          I'm a fullstack web developer, self-taught developer and I'm always looking for new challenges to learn new technologies.
        </p>
        <p>
          Always searching to apply good practices; pair programming lover.
        </p>
        <p>
          On the way to be a Senior Full-Stack Web Developer / Project Manager.
        </p>
        <h3>
          <strong>
            Teaching
          </strong>
        </h3>
        <p>
          Programming teacher at university on free times.
        </p>
        <p>
          From the basics of programming to data structures, compilers, web development, even assembly language!
        </p>
        <h3>
          <strong>
            Hobbies
          </strong>
        </h3>
        <p>
          I'm a big fan of sports, my whole weekends are spent playing wallyball, cycling, squash and padel.
        </p>
        <p>
          I'm in a Volleyball Team called 'Molten De La Sierra' where I'm challenged to get better everyday.
        </p>
        <p>
          Rubik's cube is my favorite puzzle game, I also play chess online.
        </p>
      </samp>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAboutMe"
}
</script>

<style scoped>

</style>
